export const TRACKER_PAGE_ID = 'tracker';
export const CART_PAGE_ID = 'shopping_cart';
export const THANK_YOU_PAGE_ID = 'thank_you_page';

export const ORDERS_PAGES_GROUP = [
  TRACKER_PAGE_ID,
  CART_PAGE_ID,
  THANK_YOU_PAGE_ID,
];

export const MENUS_APP_IMAGE_GALLERY_LIGHTBOX = 'menusImageGallery';

export const SPECS = {
  menusPageActions: 'specs.restaurants.menus-page-actions',
  deleteMenusAction: 'specs.restaurants.deleteMenusAppPageAction',
  exposeSEOPanelForTemplates: 'specs.restaurants.exposeSEOSettingsForTemplates',
};
